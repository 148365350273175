/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import TextField from '@mui/material/TextField';
import { Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RED, YELLOW } from "../../Colors/Colors";
import QuestionnaireDialog from "../../components/QuestionnaireDialog/QuestionnaireDialog";
import StatsCard from "../../components/StatsCard";
import CustomSelect from "../../components/UI/CustomSelect";
import {
  getQuestionTabs,
  getQuestions,
  getDashBoardData,
} from "../../redux/DashBoardReducer/DashBoardAction";
import { toggleSnackbar } from "../../redux/UtilityReducer/UtilityAction";
import { axios } from "../../utils/axios";
import CircularProgress from "@mui/material/CircularProgress";
import {
  dashboardCol,
  getActionLogScores,
  getFilterdData,
  getSumValues,
  getVulnerabilityScoreScores,
  locationFormatter,
} from "../../utils/helperFunctions";
import QuestionnaireResponseTable from "../../components/QuestionnaireResponseTable";
import { useBlocker } from "../../hooks/useBlocker";
import CustomizedDialogs from "../../components/ExportModal";
import { usePrompt } from "react-router-dom";
import { errorNotification } from "../../utils/notificationError";
import DatePicker from 'react-datepicker'; // Ensure the case is correct here
import 'react-datepicker/dist/react-datepicker.css';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      container
      item
      xs={12}
      role="tabpanel"
      hidden={value !== index}
      {...other}
      sx={{ width: "100%" }}
    >
      {value === index && (
        <Grid container item sx={{ p: 3 }}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}

function Questionnaire() {
  // dispatch,questions:
  const dispatch = useDispatch();
  // selector
  var k;

  const {
    selectedCountryLocation = "",
    countryList = [],
    countryLocationList = [],
    questionsTab = [],
    questions = [],
    listOfZones = [],
    listOfLocations = [],
    selectedZone = "",
    selectedLocation = "",
    loading,
    dashBoardData = [],
    selectedCountry = "",
  } = useSelector((state) => state?.DashBoard);

  const { user, roles } = useSelector((state) => state?.Auth);
  const listZones = listOfLocations?.find(
    (data) => data.location === selectedCountryLocation
  );
  const [open, setOpenYrsResponse] = React.useState(false);
  const [activeSubQuestion, setActiveSubQuestion] = useState([]);
  const [isNoSubQus, setIsNoSubQus] = useState(false);
  let activeCountry =
    selectedCountry ||
    dashBoardData?.filter(
      (item) => item?.location === selectedCountryLocation
    )?.[0]?.country;
  useEffect(() => {
    if (selectedCountryLocation && selectedZone) {
      // setIsProcessing(true);
      dispatch(getQuestionTabs(user?.mail?.toLowerCase()));
      dispatch(
        getQuestions(
          user?.id,
          user?.mail?.toLowerCase(),
          selectedCountryLocation,
          selectedZone,
          roles,
          selectedCountry ||
          dashBoardData?.filter(
            (item) => item?.location === selectedCountryLocation
          )?.[0]?.country
        )
      );
      dispatch(
        getDashBoardData(
          user?.id,
          user?.mail?.toLowerCase(),
          roles,
          selectedCountry,
          selectedCountryLocation,
          selectedZone
        )
      );
      // setIsProcessing(false);
    }
  }, [
    user?.mail?.toLowerCase(),
    selectedCountry,
    selectedCountryLocation,
    selectedZone,
  ]);

  // states
  const [isProcessing, setIsProcessing] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedActiveRow, setSelectedActiveRow] = useState(null);
  const [questionSet, setQuestionSet] = useState([]);
  const [isEditData, setIsEditData] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [responseSet, setResponseSet] = useState([]);
  const [lastUpdaterName, setLastUpdaterName] = useState('');
  const [activeQuestionResponse, setActiveQuestionResponse] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);
  const [newv, setTempValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [name, setName] = useState('');

  const [questionnaireTabsIndexStart, setQuestionnaireTabsIndexStart] =
    useState({
      "Security Management": 0,
      "Security Awareness": 0,
      Perimeter: 0,
      "Access Control": 0,
      "Electronic Security": 0,
      Innovation: 0,
    });
  const [scorList, setScorList] = useState({
    sectionScore: "0",
    vulnerabilityScore: "0",
    actionLog: "0",
    GITeCAndZIT: "0",
  });

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setIsBlocking(!!responseSet?.length);
  }, [responseSet]);

  useEffect(() => {
    if (questionSet.length) {
      // sectionScore/
      const selectedTabQuestionsOneSection = getFilterdData(
        questionSet,
        "section",
        questionsTab[value]
      );

      const yesAnswerdOneSection = getFilterdData(
        selectedTabQuestionsOneSection,
        "answer",
        "Yes"
      );
      let questionArr = []
      selectedTabQuestionsOneSection.forEach(element => {
        if (element.weightage === 0 && element.answer === 'No') {
          questionArr.push(...element.if_yes_enable.split('/'))
        }
      });
      const dataRowValue = selectedTabQuestionsOneSection.filter(i => !questionArr.includes(i.ques_no))
      const scoreOfAllQuestionOneSection = getSumValues(
        dataRowValue,
        "weightage"
      );

      const yesAnswerdScoreOneSection = getSumValues(
        yesAnswerdOneSection,
        "weightage"
      );
      const sectionScore = (
        (yesAnswerdScoreOneSection / scoreOfAllQuestionOneSection) *
        100
      ).toFixed(2);
      // *********************************************************************************
      // vulnerabilityScore

      const vulnerabilityScore = getVulnerabilityScoreScores(questionSet, [], false, questionsTab[value] === 'Perimeter');

      // *********************************************************************************
      // actionLog
      const actionLog = getActionLogScores(
        questionSet.filter((i) => i.answer === "No")
      );
      // *****************************************************************************
      // "Innovation" --section
      const innovationAllSectionData = getFilterdData(
        questionSet,
        "section",
        "Innovation"
      );
      const yesInnovationAllSection = getFilterdData(
        innovationAllSectionData,
        "answer",
        "Yes"
      );
      const innovationWeightage = getSumValues(
        innovationAllSectionData,
        "weightage"
      );
      const yesInnovationWeightage = getSumValues(
        yesInnovationAllSection,
        "weightage"
      );
      const GITeCAndZIT = (
        (yesInnovationWeightage / innovationWeightage) *
        100
      ).toFixed(2);
      setScorList({
        ...scorList,
        sectionScore: sectionScore === 'NaN' ? '0.00' : sectionScore,
        vulnerabilityScore,
        GITeCAndZIT,
        actionLog: actionLog,
      });
    } else {
      setScorList({
        ...scorList,
        sectionScore: "0",
        vulnerabilityScore: "0",
        GITeCAndZIT: "0",
      });
    }
  }, [value, questionSet, questions, questionsTab]);

  useEffect(() => {
    if (selectedCountryLocation === "") {
      setQuestionSet(
        questions?.filter(
          (item) => item?.zone === locationFormatter(listOfZones[0])
        )
      );

      dispatch({
        type: "SELECT_LOCATION",
        payload: listOfLocations[0]?.location,
      });
      dispatch({
        type: "SELECT_ZONE",
        payload: listOfZones[0],
      });
    } else {
      const findSelectedCountryLocation =
        countryList.find((c) => c.country === selectedCountry) || {};
      const addZone =
        findSelectedCountryLocation?.countryLocations?.map((cl) => {
          return {
            location: cl,
            zones: listOfLocations?.find((l) => l.location === cl)?.zones,
          };
        }) || [];

      dispatch({
        type: "SET_LIST_OF_LOCATIONS",
        payload: addZone,
      });
      const findLocationList = listOfLocations.find(
        (l) => l.location === selectedCountryLocation
      );
      dispatch({
        type: "SET_LIST_OF_ZONES",
        payload: findLocationList?.zones || [],
      });
      setQuestionSet(
        questions?.filter(
          (item) => item?.zone === locationFormatter(selectedZone)
        )
      );
    }
  }, [questions, value]);

  const allQuestionDataCSV = questionSet?.map((data, index) => {
    return {
      ques_no: index + 1,
      section: data.section,
      question: data.question,
      zone: data.zone,
      response: data.answer,
      description: data.gap_description || data.remark,
      uID: data.id,
      status: data.log_status,
      weightage: data.weightage
    };
  });
  useEffect(() => {
    if (questionSet.length) {
      const securityManagementStartIndex = 0;
      const securityAwarenessStartIndex = questionSet?.filter(
        (currentQue) =>
          currentQue?.section === questionsTab[0] &&
          (currentQue.is_sub_question === "No" ||
            currentQue.is_parent_question === "Yes")
      )?.length;

      const perimeterStartIndex =
        questionSet?.filter(
          (currentQue) =>
            currentQue?.section === questionsTab[1] &&
            (currentQue.is_sub_question === "No" ||
              currentQue.is_parent_question === "Yes")
        )?.length + securityAwarenessStartIndex;

      const accessControlStartIndex =
        questionSet?.filter(
          (currentQue) =>
            currentQue?.section === questionsTab[2] &&
            (currentQue.is_sub_question === "No" ||
              currentQue.is_parent_question === "Yes")
        )?.length + perimeterStartIndex;

      const electronicSecurityStartIndex =
        questionSet?.filter(
          (currentQue) =>
            currentQue?.section === questionsTab[3] &&
            (currentQue.is_sub_question === "No" ||
              currentQue.is_parent_question === "Yes")
        )?.length + accessControlStartIndex;

      const innovationSecurityStartIndex =
        questionSet?.filter(
          (currentQue) =>
            currentQue?.section === questionsTab[4] &&
            (currentQue.is_sub_question === "No" ||
              currentQue.is_parent_question === "Yes")
        )?.length + electronicSecurityStartIndex;

      setQuestionnaireTabsIndexStart({
        "Security Management": securityManagementStartIndex,
        "Security Awareness": securityAwarenessStartIndex,
        Perimeter: perimeterStartIndex,
        "Access Control": accessControlStartIndex,
        "Electronic Security": electronicSecurityStartIndex,
        Innovation: innovationSecurityStartIndex,
      });
    }
  }, [questionSet]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickOpen = () => {
    setOpenYrsResponse(true);
  };
  const handleClose = () => {
    setIsEditData(false);
    setOpenYrsResponse(false);
    setActiveQuestionResponse([]);
    if (isEditData) return;
    setResponseSet(
      responseSet.filter((item) => item?.id !== selectedActiveRow?.id)
    );
  };
  const handleSave = () => {
    const subQusId =
      selectedActiveRow[
        `if_${selectedActiveRow?.status === "Yes" ? "no" : "yes"?.toLowerCase()
        }_enable`
      ].split("/");

    const activeSubQuestionData = questionSet?.filter((q) =>
      subQusId.includes(q.ques_no?.toString())
    );
    const updateDataArray = activeSubQuestionData?.map((item) => {
      return {
        id: item.id,
        status: null,
        gap_description: item.gap_description,
      };
    });
    const qesIDs = updateDataArray?.map((i) => i.id);

    if (isEditData && responseSet.length) {
      const updatedData = responseSet?.map((i) => {
        const dataRow = activeQuestionResponse?.find((v) => v.id === i.id);
        return { ...i, ...dataRow };
      });
      const saveData = updatedData?.find((i) => qesIDs.includes(i.id))
        ? updatedData
        : [...updatedData, ...updateDataArray];
      setResponseSet(saveData);
    } else {
      const resData = [...responseSet, ...activeQuestionResponse];
      const saveData = resData.find((i) => qesIDs.includes(i.id))
        ? resData
        : [...resData, ...updateDataArray];
      setResponseSet(saveData);
    }
    setActiveQuestionResponse([]);
    setActiveSubQuestion([]);
    setOpenYrsResponse(false);
    setIsEditData(false);
  };
  useEffect(() => {
    if (questionsTab[value] === 'Perimeter') {
      let data = []

      if (selectedCountryLocation === "") {
        data = questions?.filter(
          (item) => item?.zone === locationFormatter(listOfZones[0])
        );
      } else {
        data = questions?.filter(
          (item) => item?.zone === locationFormatter(selectedZone)
        )
      }
      const perimeterArray = data.filter(q => q.section === questionsTab[value])
      const filterArray = data.filter(q => q.section !== questionsTab[value])

      if (!perimeterArray[0]?.answer) {
        setQuestionSet([...filterArray, perimeterArray[0]])
      }
      if (perimeterArray[0]?.answer === 'Yes') {
        if (perimeterArray[1]?.answer === 'Yes') {
          const filteredData = perimeterArray?.slice(0, 2) || []
          setQuestionSet([...filterArray, ...filteredData])
        } else if (!perimeterArray[1]?.answer) {
          setQuestionSet([...filterArray, perimeterArray[0], perimeterArray[1]])
        }
      } else if (perimeterArray[0]?.answer === 'No') {
        const deleteData = perimeterArray.splice(1, 1)
        setDeleteArray([deleteData.id])
        setQuestionSet([...filterArray, ...perimeterArray])
      }
    }
  }, [value, questionsTab, selectedZone, questions])

  const handleCloseDialog = (dataValue) => {
    // if (isNoSubQus) handleClose();
    if (!selectedRow.gap_description.trim() && dataValue?.status !== "Yes") {
      return;
    }
    setIsNoSubQus(false);

    setOpenDialog(false);
    setSelectedRow(null);
  };

  const handleForceCloseDialog = (dataValues) => {
    setOpenDialog(false);
    setSelectedRow(null);
    setIsNoSubQus(false);
    if (isNoSubQus) handleClose();
    if (dataValues?.status === "Yes") return;
    setResponseSet(responseSet.filter((item) => item?.id !== selectedRow.id));
    setActiveQuestionResponse(
      activeQuestionResponse.filter((item) => item?.id !== selectedRow.id)
    );
  };

  const handleChange = (event, newValue) => {
    if (responseSet?.length === 0) {
      setValue(newValue);
    } else {
      const confirm = window.confirm(
        "You have unsaved changes. Draft will be discarded."
      );
      if (confirm) {
        setResponseSet([]);
        setValue(newValue);
      } else {
        return null;
      }
    }
  };

  const addDescription = (id, desc) => {
    setSelectedRow({
      ...selectedRow,
      gap_description: desc,
    });

    setResponseSet(
      responseSet?.map((item) =>
        item?.id === id ? { ...item, gap_description: desc } : item
      )
    );
    setActiveQuestionResponse(
      activeQuestionResponse?.map((item) =>
        item?.id === id ? { ...item, gap_description: desc } : item
      )
    );
    setActiveQuestionResponse(
      activeQuestionResponse?.map((item) =>
        item?.id === id ? { ...item, gap_description: desc } : item
      )
    );
  };
  useEffect(() => {
    const fetchAuditorData = async () => {
      try {
        const email = user?.mail?.toLowerCase();
        const location = selectedCountryLocation;
        const location_type = selectedZone;

        // Make the API call with the selected email, location, and location type
        const response = await axios.post('/save-questionnaire-myclick', {
          email,
          location,
          location_type,
        });

        const { auditor_name, date_reviewed } = response.data || {};

        // Reset state when no data is returned
        if (!auditor_name || !date_reviewed) {
          setLastUpdaterName(null);   // Clear the previous auditor name
          setSelectedDate(null);      // Clear the previous date
        } else {
          // Set the extracted data to state if data exists
          setLastUpdaterName(auditor_name);
          setSelectedDate(new Date(date_reviewed));
        }
      } catch (error) {
        console.error('Error fetching auditor data:', error);
      }
    };

    // Fetch data on component mount and when selectedCountryLocation or selectedZone change
    if (user?.mail) fetchAuditorData();

  }, [user?.mail, selectedCountryLocation, selectedZone]);

  //   useEffect(() => {
  //   const fetchAuditorData = async () => {
  //     try {
  //       const email = user?.mail?.toLowerCase();
  //       const location = selectedCountryLocation;
  //       const location_type = selectedZone;

  //       // Make the API call with the selected email, location, and location type
  //       const response = await axios.post('/save-questionnaire-myclick', {
  //         email,
  //         location,
  //         location_type,
  //       });

  //       const { auditor_name, date_reviewed } = response.data || {};

  //       // Set the extracted data to state
  //       if (auditor_name) setLastUpdaterName(auditor_name);
  //       if (date_reviewed) setSelectedDate(new Date(date_reviewed));
  //     } catch (error) {
  //       console.error('Error fetching auditor data:', error);
  //     }
  //   };

  //   // Fetch data on component mount and when selectedCountryLocation or selectedZone change
  //   if (user?.mail) fetchAuditorData();

  // }, [user?.mail, selectedCountryLocation, selectedZone]);







  //   <button className="custom-date-input" onClick={onClick} ref={ref}>
  //     {value || 'Select Date'}
  //     <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
  //   </button>
  // ));
  // const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  //   <button
  //     className="custom-date-input"
  //     onClick={onClick}
  //     ref={ref}
  //     style={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       padding: '8px 12px',
  //       border: '1px solid #ccc',
  //       borderRadius: '4px',
  //       backgroundColor: '#fff',
  //       cursor: 'pointer',
  //       fontSize: '16px',
  //       fontFamily: 'Arial, sans-serif',
  //       width: '100%',
  //       textAlign: 'left',
  //       boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  //       transition: 'border-color 0.2s, box-shadow 0.2s',
  //     }}
  //   >
  //     {value || 'Select Date'}
  //     <FontAwesomeIcon
  //       icon={faCalendarAlt}
  //       style={{
  //         marginLeft: '8px',
  //         fontSize: '18px',
  //         color: '#007bff',
  //       }}
  //     />
  //   </button>
  // ));
  // const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  //   <div style={{ position: 'relative', width: '100%', marginBottom: '20px' }}>
  //     <label
  //       style={{
  //         position: 'absolute',
  //         top: '-8px',
  //         left: '12px',
  //         backgroundColor: '#f5f5f5',
  //         padding: '0 4px',
  //         fontSize: '12px',
  //         color: '#666',
  //         fontFamily: 'Arial, sans-serif',
  //       }}
  //     >
  //       Last Reviewed
  //     </label>
  //     <button
  //       className="custom-date-input"
  //       onClick={onClick}
  //       ref={ref}
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         padding: '10px 14px',
  //         border: '1px solid #ccc',
  //         borderRadius: '4px',
  //         backgroundColor: '#f5f5f5',
  //         cursor: 'pointer',
  //         fontSize: '16px',
  //         fontFamily: 'Arial, sans-serif',
  //         width: '100%',
  //         textAlign: 'left',
  //         boxShadow: 'none',
  //         transition: 'border-color 0.2s, box-shadow 0.2s',
  //       }}
  //     >
  //       {value || 'Select Date'}
  //       <FontAwesomeIcon
  //         icon={faCalendarAlt}
  //         style={{
  //           marginLeft: '8px',
  //           fontSize: '18px',
  //           color: '#007bff',
  //         }}
  //       />
  //     </button>
  //   </div>
  // ));
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div style={{ position: 'relative', width: '100%', marginBottom: '20px', marginTop: '15px' }}>
      <label
        style={{
          position: 'absolute',
          top: '-10px',
          left: '14px',
          backgroundColor: '#fff',
          padding: '0 6px',
          fontSize: '12px',
          color: '#666',
          fontFamily: 'Avantt',
        }}
      >
        Last Reviewed
      </label>
      <button

        className="custom-date-input"
        onClick={onClick}
        ref={ref}
        style={{
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          padding: '12px 16px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: '#fff',
          cursor: 'pointer',
          fontSize: '16px',
          fontFamily: 'Avantt',
          width: '110%',
          textAlign: 'left',
          boxShadow: 'none',
          transition: 'border-color 0.2s, box-shadow 0.2s',
        }}
      >
        {value || 'Select Date'}
        <FontAwesomeIcon
          icon={faCalendarAlt}
          style={{
            marginLeft: '20px',
            fontSize: '18px',
            color: '#007bff',
          }}
        />
      </button>
    </div>
  ));




  //formate the date 
  const formatDate = (date) => {
    if (!date) return '';

    const pad = (num) => num.toString().padStart(2, '0');
    const padMilliseconds = (num) => num.toString().padStart(3, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = padMilliseconds(date.getMilliseconds());

    return `${year}-${month}-${day}`;
  };



  // Renamed function
  const handleLastUpdaterChange = (e) => {
    setLastUpdaterName(e.target.value);
  };

  const saveQuestionnaireAnswers = async () => {
    try {

      console.log('responseSet.length:', responseSet.length);
      console.log('lastUpdaterName.length:', lastUpdaterName.length);
      console.log('selected date:', selectedDate);

      if (responseSet.length === 0 && lastUpdaterName.length !== 0) {
        try {
          const response = await axios.post("/save-questionnaire-dashboard", {
            email: user?.mail?.toLowerCase(),
            oid: user?.id,
            location: selectedCountryLocation,
            location_type: selectedZone,
            country: selectedCountry || dashBoardData?.filter(
              (item) => item?.location === selectedCountryLocation
            )?.[0]?.country,
            name: "myclick",
            dataa: {
              auditor_name: lastUpdaterName,
              date_reviewed: selectedDate.toISOString() // Ensure correct format
            }
          });

          console.log('Response:', response);

          dispatch(
            getQuestions(
              user?.id,
              user?.mail?.toLowerCase(),
              selectedCountryLocation,
              selectedZone,
              roles,
              selectedCountry || dashBoardData?.filter(
                (item) => item?.location === selectedCountryLocation
              )?.[0]?.country
            )
          );

          return dispatch(toggleSnackbar("No Question Selected", "error"));
        } catch (error) {
          console.error('Error during POST request:', error);
        }

      }



      // if (responseSet.length === 0 && lastUpdaterName.length !==0) {
      //   console.log("hello you are step into ur destiny")       
      //   const response = await axios.post("/save-questionnaire-dashboard", {
      //   email: user?.mail?.toLowerCase(),
      //   oid: user?.id,
      //     location: selectedCountryLocation,
      //     location_type: selectedZone,
      //     country:
      //     selectedCountry ||
      //     dashBoardData?.filter(
      //       (item) => item?.location === selectedCountryLocation
      //     )?.[0]?.country,
      //     name:"myclick",
      //     dataa: {
      //       auditor_name: lastUpdaterName,
      //       date_reviewed: selectedDate, // Replace with actual data review value
      //     }


      //   });
      //   dispatch(
      //     getQuestions(
      //       user?.id,
      //       user?.mail?.toLowerCase(),
      //       selectedCountryLocation,
      //       selectedZone,
      //       roles,
      //       selectedCountry ||
      //       dashBoardData?.filter(
      //         (item) => item?.location === selectedCountryLocation
      //       )?.[0]?.country
      //     )
      //   );
      //   return dispatch(toggleSnackbar("No Question Selected", "error"));
      // }
      setIsProcessing(true)
      // const updateDataArray = [];
      const idList =
        responseSet.filter((i) => i.status === "No")?.map((r) => r.id) || [];
      const selectedData = questionSet.filter((i) => idList.includes(i.id));
      const subQuestionArray =
        selectedData.map((i) => i?.if_yes_enable?.split("/")) || [];
      const subIds = [].concat.apply([], subQuestionArray);
      const activeSubQuestionData = questionSet?.filter((q) =>
        subIds.includes(q.ques_no?.toString())
      );
      const delete_data = activeSubQuestionData.map((i) => i.id) || [];
      // updateDataArray.push([...activeSubQuestionData?.map((item) => {
      //   return {
      //     id: item.id,
      //     status: null,
      //     gap_description: '',
      //     remark: ''
      //   };
      // })]);
      const updatedData = dashBoardData?.find(
        (i) =>
          i.country === selectedCountry &&
          i.location === selectedCountryLocation
      );

      const response = await axios.post("/save-questionnaire-dashboard", {
        email: user?.mail?.toLowerCase(),
        oid: user?.id,
        delete_data: [...delete_data, ...deleteArray],
        data: responseSet,
        location: selectedCountryLocation,
        location_type: selectedZone,
        country:
          selectedCountry ||
          dashBoardData?.filter(
            (item) => item?.location === selectedCountryLocation
          )?.[0]?.country,
        zone: updatedData?.zone,
        bu: updatedData?.business_unit,

      });
      response?.data?.status &&
        dispatch(toggleSnackbar(response?.data?.message, "success"));
      setResponseSet([]);

      dispatch(
        getQuestions(
          user?.id,
          user?.mail?.toLowerCase(),
          selectedCountryLocation,
          selectedZone,
          roles,
          selectedCountry ||
          dashBoardData?.filter(
            (item) => item?.location === selectedCountryLocation
          )?.[0]?.country
        )
      );
      setIsProcessing(false)
    } catch (e) {
      setIsProcessing(false)
      errorNotification(e.message);
      console.log(e.message);
    }
  };

  // variables
  const isAuditor = roles === "Auditor";
  const isReviewer = roles === "Reviewer";
  const isApprover = roles === "Approver";

  const returnLogStatus = (status) => {
    switch (status) {
      case "completed":
        return "Completed";
      case "inProgress":
        return "In Progress";
      case "delayed":
        return "Delayed";
      case "not_Started_yet":
        return "Not Started Yet";
      default:
        return "";
    }
  };

  usePrompt(
    "It looks like you have been editing something \nif you leave before saving, then your changes will be lost.",
    isBlocking
  );

  return (
    <>
      {loading ? (
        <div className="loading-wrapper">
          <CircularProgress style={{ color: YELLOW }} />
        </div>
      ) : (
        <>
          <Grid container sx={{ p: 1 }}>
            <StatsCard
              title="Section Score"
              value={`${scorList.sectionScore}%`}
              color={YELLOW}
            />
            <StatsCard
              title="Location Vulnerability Score"
              value={`${scorList.vulnerabilityScore}`}
              color={YELLOW}
            />
            <StatsCard
              title="Action Log Completion"
              value={`${scorList.actionLog}`}
              color={YELLOW}
            />
            <StatsCard
              title="GITeC & ZITec Sction Score"
              value={`${scorList.GITeCAndZIT}%`}
              color={YELLOW}
            />
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#000",
                height: "2px",
              }}
            />
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                padding: 1,
                mt: {
                  xs: 1,
                  md: 2,
                },
                mb: {
                  xs: 1,
                  md: 2,
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  margin: "auto",
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "0.75rem 2rem",
                  borderRadius: "4px",
                  width: "100%",
                  // width: {
                  //   xs: "100%",
                  //   md: "auto",
                  // },
                  fontSize: "14px",
                }}
              >
                <span style={{ color: YELLOW }}>Location</span> :{" "}
                {selectedCountryLocation}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                padding: 1,
                mt: {
                  xs: 1,
                  md: 2,
                },
                mb: {
                  xs: 1,
                  md: 2,
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  margin: "auto",
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "0.75rem 2rem",
                  borderRadius: "4px",
                  width: "100%",
                  // width: {
                  //   xs: "100%",
                  //   md: "auto",
                  // },
                  fontSize: "14px",
                }}
              >
                <span style={{ color: YELLOW }}>Country</span> : {activeCountry}
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                padding: 1,
                mt: {
                  xs: 1,
                  md: 2,
                },
                mb: {
                  xs: 1,
                  md: 2,
                },
                justifyContent: "space-around",
              }}
            >
              {isAuditor && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "gray",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      padding: {
                        xs: "0.5rem 2rem",
                        md: "0rem 2rem",
                      },
                    }}
                    onClick={() => setResponseSet([])}
                  >
                    DISCARD
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: YELLOW,
                      "&:hover": {
                        backgroundColor: YELLOW,
                      },
                      color: "black",
                      padding: {
                        xs: "0.5rem 2rem",
                        md: "0rem 2rem",
                      },
                    }}
                    disabled={isProcessing}
                    onClick={saveQuestionnaireAnswers}
                  >
                    SAVE
                    {!!isProcessing && <CircularProgress size={25} style={{ marginLeft: 10 }} />}
                  </Button>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#000",
                height: "2px",
              }}
            />
            <Grid
              container
              item
              xs={12}
              sm={2}
              md={2}
              sx={{
                padding: 1,
                mt: {
                  xs: 1,
                  md: 2,
                },
                mb: {
                  xs: 1,
                  md: 2,
                },
              }}
            >
              <CustomSelect
                onChange={(e) => {
                  setResponseSet([]);
                  dispatch({
                    type: "SELECT_LOCATION",
                    payload: e.target.value,
                  });
                  dispatch({
                    type: "SET_SELECTED_COUNTRY_LOCATION",
                    payload: e.target.value,
                  });
                  const selectedZonesValue = listOfLocations.find(
                    (i) => i.location === e.target.value
                  )?.zones;
                  dispatch({
                    type: "SELECT_ZONE",
                    payload: selectedZonesValue[0],
                  });
                  dispatch({
                    type: "SET_LIST_OF_ZONES",
                    payload: selectedZonesValue || [],
                  });
                  setQuestionSet(
                    questions?.filter(
                      (item) =>
                        item?.zone === locationFormatter(selectedZonesValue[0])
                    )
                  );
                }}
                value={selectedCountryLocation}
                displayEmpty={false}
                label="Select Location"
                placeholder="Select Location"
                options={listOfLocations?.map((item) => ({
                  value: item.location,
                  lable: item.location,
                }))}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={2}
              md={2}
              sx={{
                padding: 1,
                mt: {
                  xs: 1,
                  md: 2,
                },
                mb: {
                  xs: 1,
                  md: 2,
                },
              }}
            >
              {isAuditor ? (
                <CustomSelect
                  disabled={!selectedCountryLocation}
                  onChange={(e) => {
                    setResponseSet([]);
                    dispatch({
                      type: "SELECT_ZONE",
                      payload: e.target.value,
                    });
                    setQuestionSet(
                      questions?.filter(
                        (item) =>
                          item?.zone === locationFormatter(e.target.value)
                      )
                    );
                  }}
                  value={selectedZone}
                  displayEmpty={false}
                  label="Select Location Type"
                  placeholder="Select Location Type"
                  options={listZones?.zones?.map((item) => ({
                    value: item,
                    lable: item,
                  }))}
                />
              ) : (
                <CustomSelect
                  disabled={!selectedCountryLocation}
                  onChange={(e) => {
                    setResponseSet([]);
                    dispatch({
                      type: "SELECT_ZONE",
                      payload: e.target.value,
                    });
                    setQuestionSet(
                      questions?.filter(
                        (item) =>
                          item?.zone === locationFormatter(e.target.value)
                      )
                    );
                  }}
                  value={locationFormatter(selectedZone)}
                  displayEmpty={false}
                  label="Select Location Type"
                  placeholder="Select Location Type"
                  options={listZones?.zones?.map((item) => ({
                    value: locationFormatter(item),
                    lable: locationFormatter(item),
                  }))}
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                padding: 1,
                mt: isAuditor
                  ? { xs: 1, md: 1 } // Reduced margin-top for Auditor
                  : { xs: 1, md: 2 }, // Default margin-top for Reviewer
                mb: {
                  xs: 1,
                  md: 2,
                },
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              {/* Auditor Last Name Field */}
              <TextField
                disabled={!isAuditor || !selectedCountryLocation}
                onChange={isAuditor ? handleLastUpdaterChange : undefined}
                label={isAuditor ? "Auditor Last Updated" : "Auditor Name"}
                placeholder={isAuditor ? "Enter auditor Name" : "View auditor Name"}
                value={lastUpdaterName}
                fullWidth
                InputProps={{
                  sx: {
                    height: '40px', // Adjust height for both
                    padding: '4px 8px',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: { color: isAuditor ? 'black' : 'gray' },
                }}
                sx={{
                  maxWidth: '190px',
                }}
              />

              {/* Date Picker Field */}
              {isAuditor ? (
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  customInput={
                    <CustomInput
                      sx={{
                        height: '40px', // Same height as TextField
                        padding: '4px 8px',
                        maxWidth: '170px',
                      }}
                    />
                  }
                  fullWidth
                />
              ) : (
                <TextField
                  disabled
                  label="Date Reviewed"
                  value={
                    selectedDate instanceof Date && !isNaN(selectedDate)
                      ? formatDate(selectedDate)
                      : 'No date available'
                  }
                  fullWidth
                  InputProps={{
                    sx: {
                      height: '40px',
                      padding: '4px 8px',
                    },
                  }}
                  sx={{
                    maxWidth: '170px',
                  }}
                />
              )}
            </Grid>






            <Grid
              container
              item
              xs={12}
              sm={10}
              md={10}
              sx={{
                padding: 1,
                mt: {
                  xs: 1,
                  md: 2,
                },
                mb: {
                  xs: 1,
                  md: 2,
                },
              }}
            />
            <Grid container item xs={12}>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={true}
                  allowScrollButtonsMobile
                >
                  {questionsTab?.map((tabItem, index) => (
                    <Tab
                      key={index}
                      label={tabItem}
                      sx={{
                        backgroundColor: "#fff",
                        color: "#000",
                        border: 1,
                        fontSize1: "14px",
                        "&.Mui-selected": {
                          color: "black",
                          backgroundColor: YELLOW,
                        },
                        "&.MuiTab-root": {
                          fontSize: "12px",
                          padding: "0.25rem 1rem",
                        },
                      }}
                    />
                  ))}
                </Tabs>

                <div className="mr-7">
                  <div
                    onClick={handleClickOpenModal}
                    className="d-flex align-items-center"
                  >
                    <span className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon">
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="SaveAltIcon"
                      >
                        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path>
                      </svg>
                    </span>
                    Export all
                  </div>
                </div>
              </div>

              <Grid container item xs={12} sx={{ pl: 2, pr: 2 }}>
                {questionsTab?.map((item, index) => (
                  <TabPanel value={value} index={index}>
                    <Grid container item xs={12} sx={{ width: "100%" }}>
                      <DataGrid
                        className="remove-search-boarder"
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        autoHeight
                        sx={{ width: "100%" }}
                        rows={questionSet
                          ?.filter(
                            (currentQue) =>
                              currentQue?.section === item &&
                              (currentQue.is_sub_question === "No" ||
                                currentQue.is_parent_question === "Yes")
                          )
                          ?.map((v, i) => {
                            const filterData = responseSet?.find(
                              (item) => item?.id === v?.id
                            );
                            const gap_description =
                              filterData?.status === "Yes" ||
                                v?.answer === "Yes"
                                ? filterData?.gap_description || v?.remark
                                : filterData?.gap_description ||
                                v?.gap_description;
                            return {
                              ...v,
                              index: questionnaireTabsIndexStart[item] + i + 1,
                              gap_description,
                            };
                          })}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        columns={[
                          {
                            field: "index",
                            headerName: "S.No.",
                            minWidth: 72,
                            flex: 1,
                          },
                          {
                            field: "section",
                            headerName: "Section",
                            flex: 1,
                            cellClassName: "QuestionCell",
                            minWidth: 180,
                          },
                          {
                            field: "question",
                            headerName: "Question",
                            flex: 1,
                            cellClassName: "QuestionCell",
                            minWidth: 560,
                          },
                          {
                            field: "zone",
                            headerName: "Zone",
                            flex: 1,
                            cellClassName: "QuestionCell",
                            minWidth: 150,
                          },
                          {
                            field: "weightage",
                            headerName: "Weightage",
                            flex: 1,
                            cellClassName: "QuestionCell",
                            minWidth: 150,
                          },
                          // {
                          //   field: "assessment",
                          //   headerName: "Assessment",
                          //   flex: 1,
                          //   minWidth: 560,

                          //   cellClassName: "QuestionCell",
                          // },
                          {
                            headerName: "Response",
                            field: "response",
                            flex: 1,
                            minWidth: 180,
                            valueFormatter: (row) =>
                              questionSet?.find((q) => q.id === row.id)?.answer || "",
                            renderCell: (row) => {

                              let value =
                                responseSet?.filter((item) => item?.id === row?.row?.id)[0]?.status ||
                                row?.row?.answer ||
                                "";
                              setTempValue(value)
                              const isChangeRow = responseSet.filter(
                                (item) => item?.id === row?.row?.id && item?.status === "No"
                              );
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    padding: "4px",
                                  }}
                                >
                                  <select
                                    style={{
                                      width: "100%",
                                      padding: "4px",
                                      fontWeight: ["Yes", "Yes with Remark", "No"].includes(newv)
                                        ? "bold"
                                        : "normal",
                                      background: ["Yes with Remark", "Yes"].includes(newv)
                                        ? "#00800080"
                                        : newv === "No"
                                          ? "#fc636380"
                                          : "transparent",
                                    }}
                                    value={value}
                                    disabled={!isAuditor}
                                    onClick={() => {
                                      value = "new";
                                      setTempValue("")
                                      k = row.row;

                                    }}
                                    onChange={(e, value) => {
                                      const responseValue =
                                        "Yes with Remark" === e.target.value ? "Yes" : e.target.value;
                                      const isExisting = responseSet
                                        ?.map((item) => item?.id)
                                        .includes(row?.row?.id);

                                      if (isExisting) {
                                        setResponseSet(
                                          responseSet?.map((item) =>
                                            item?.id === row?.row?.id
                                              ? {
                                                ...item,
                                                status: responseValue,
                                                gap_description: ["Yes with Remark", "Yes"].includes(
                                                  e.target.value
                                                )
                                                  ? null
                                                  : item?.gap_description,
                                              }
                                              : item
                                          )
                                        );

                                      } else {
                                        setResponseSet([
                                          ...responseSet,
                                          {
                                            id: row?.row?.id,
                                            status: responseValue,
                                            gap_description: null,
                                            weightage: row?.row?.weightage,
                                          },
                                        ]);

                                      }

                                      if (e.target.value === "No") {

                                        if (row?.row?.if_no_enable !== "0") {

                                          const subQusId = row?.row?.if_no_enable.split("/");

                                          const activeSubQuestionData = questionSet.filter((q) =>
                                            subQusId.includes(q.ques_no?.toString())
                                          );

                                          setActiveSubQuestion(activeSubQuestionData);
                                          setSelectedActiveRow({
                                            ...row?.row,
                                            status: e.target.value,
                                          });

                                          handleClickOpen();
                                          setIsNoSubQus(true);
                                        }
                                        setSelectedRow({
                                          id: row?.row?.id,
                                          status: e.target.value,
                                          gap_description: null,
                                        });

                                        if (row?.row?.weightage <= 0) return;

                                        handleOpenDialog();
                                      }

                                      if (e.target.value === "Yes with Remark") {

                                        setSelectedRow({
                                          id: row?.row?.id,
                                          status: responseValue,
                                          gap_description: null,
                                        });

                                        handleOpenDialog();
                                      }

                                      if (
                                        ["Yes with Remark", "Yes"].includes(e.target.value) &&
                                        row?.row?.if_yes_enable !== "0"
                                      ) {

                                        const subQusId = row?.row?.if_yes_enable.split("/");

                                        const activeSubQuestionData = questionSet.filter((q) =>
                                          subQusId.includes(q.ques_no?.toString())
                                        );

                                        setActiveSubQuestion(activeSubQuestionData);
                                        setSelectedActiveRow({
                                          ...row?.row,
                                          status: responseValue,
                                        });

                                        handleClickOpen();
                                      }
                                    }}
                                  >
                                    <option value="" disabled>
                                      Response
                                    </option>
                                    {["Yes", "Yes with Remark", "No"].map((item, index) => (
                                      <option value={item} key={index}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    style={{
                                      padding: "4px",
                                      marginLeft: "5px",
                                      outline: "none",
                                      border: "none",
                                      backgroundColor:
                                        responseSet.filter((item) => item?.id === row?.row?.id).length ===
                                          0
                                          ? "grey"
                                          : YELLOW,
                                      color: "black",
                                      borderRadius: "4px",
                                      cursor:
                                        responseSet.filter((item) => item?.id === row?.row?.id).length ===
                                          0
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                    disabled={
                                      responseSet.filter((item) => item?.id === row?.row?.id).length ===
                                      0
                                    }
                                    onClick={() => {

                                      setResponseSet(
                                        responseSet.filter((item) => item?.id !== row?.row?.id)
                                      );

                                    }}
                                  >
                                    <i className="fa-solid fa-arrows-rotate"></i>
                                  </button>
                                  {((!!responseSet.find(
                                    (item) => item?.id === row?.row?.id && item.status === "Yes"
                                  ) &&
                                    row?.row.answer === "Yes" &&
                                    row?.row?.is_parent_question === "Yes") ||
                                    (!responseSet.length && row?.row.answer === "Yes")) &&
                                    row?.row?.is_parent_question === "Yes" && (
                                      <button
                                        style={{
                                          marginLeft: 4,
                                          borderRadius: 4,
                                          padding: "0px 5px",
                                          background: "#f3dc13",
                                          border: 0,
                                        }}
                                        onClick={() => {

                                          const qes = responseSet.find(
                                            (item) => item?.id === row?.row?.id
                                          );

                                          const activeQes = qes?.status || row?.row.answer;
                                          const subQusId = row?.row?.[
                                            activeQes === "Yes" ? "if_yes_enable" : "if_no_enable"
                                          ].split("/");

                                          const activeSubQuestionData = questionSet.filter((q) =>
                                            subQusId.includes(q.ques_no?.toString())
                                          );

                                          setActiveSubQuestion(activeSubQuestionData);
                                          setIsEditData(true);
                                          setSelectedActiveRow({
                                            ...row?.row,
                                          });
                                          setActiveQuestionResponse(responseSet);
                                          console.log("Opening Sub Question Dialog for parent question");
                                          handleClickOpen();
                                        }}
                                      >
                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                      </button>
                                    )}
                                </div>
                              );
                            },
                          },
                          {
                            field: "gap_description",
                            headerName: "Description",
                            flex: 1,
                            cellClassName: "QuestionCell",
                            minWidth: 560,
                          },
                          {
                            headerName: "Status",
                            flex: 1,
                            field: "status",
                            valueFormatter: (row) =>
                              questionSet?.find((q) => q.id === row.id)
                                ?.log_status || "",
                            renderCell: (row) => {
                              return (
                                <p>{returnLogStatus(row?.row?.log_status)}</p>
                              );
                            },
                            minWidth: 150,
                          },
                          {
                            headerName: "Unique ID",
                            flex: 1,
                            // field: "id",
                            cellClassName: "QuestionCell",
                            minWidth: 150,
                            renderCell: (row) => {
                              return <p>{`SAT_${row?.row?.id}`}</p>;
                            },
                          },
                          {
                            field: "last_answer_changed",
                            headerName: "Answer Updated On",
                            renderCell: (row) => {
                              console.log("last_answer_changed", row?.row?.last_answer_changed);
                              return (
                                <>
                                  {row?.row?.last_answer_changed && (
                                    <p>
                                      {new Date(
                                        row?.row?.last_answer_changed
                                      ).toLocaleDateString()}
                                    </p>
                                  )}
                                </>
                              );
                            },
                            flex: 1,
                            cellClassName: "QuestionCell",
                            minWidth: 150,
                          },
                        ]}
                        // pageSize={10}
                        // rowsPerPageOptions={[10, 50, 100]}
                        disableSelectionOnClick
                      />
                    </Grid>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <QuestionnaireResponseTable
            handleClose={handleClose}
            open={open}
            selectedRow={selectedActiveRow}
            activeSubQuestion={activeSubQuestion}
            setActiveSubQuestion={setActiveSubQuestion}
            activeQuestionResponse={activeQuestionResponse}
            setActiveQuestionResponse={setActiveQuestionResponse}
            setSelectedRow={setSelectedRow}
            handleSave={handleSave}
            isEditData={isEditData}
            responseSet={responseSet}
            isAuditor={isAuditor}
            handleOpenDialog={handleOpenDialog}
          />
          <QuestionnaireDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            selectedRow={selectedRow}
            addDescription={(id, text) => addDescription(id, text)}
            handleForceCloseDialog={handleForceCloseDialog}
          />
          <CustomizedDialogs
            allQuestionDataCSV={allQuestionDataCSV}
            open={openModal}
            handleClose={handleCloseModal}
          />
        </>
      )}
    </>
  );
}

export default Questionnaire;














